body {
  margin: 0;
  padding: 0;
  min-width: 1400px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
#root * {
  box-sizing: border-box;
}
#root .handsontable * {
  box-sizing: content-box;
}
#root .htDatepickerHolder .pika-button {
  box-sizing: border-box;
}
.htContextMenu {
  font-size: 12px;
}
